import { useTrackLogin, useTrackUser } from '~/composables/gtm/useTrackUser';
import { useTrackItemView } from '~/composables/gtm/useEcomTracking';
import { getOgTitle } from '~/composables/seo/opengraph';

let pageView = null;

export function useTrackPageView(
    pageGroup,
    page,
    userId,
    price = null,
    product = null,
) {
    if (process.server) return;
    let category = '';
    if (page?.breadcrumbs?.length > 2) {
        category = page?.breadcrumbs[page?.breadcrumbs?.length - 2].text;
    } else {
        try {
            category = page?.breadcrumbs[page?.breadcrumbs?.length - 1].text;
        } catch {
            if (page?.title) {
                category = page.title;
            } else {
                category = '';
            }
        }
    }
    if (category === undefined) {
        //Fallback
        category = '';
    }
    pageView = {
        event: 'virtual_pageview',
        content_group: pageGroup,
        content_category: category,
        page_title: getOgTitle(page),
        page_location: document.location.href,
    };
    if (!datalayerContainsEvent('cf_after_consent_update')) {
        window.addEventListener('cf_consent_loaded', function (event) {
            event.target.removeEventListener('cf_consent_loaded', event, false);
            if (pageView !== null) {
                addUserToDatalayer(userId);
                window?.dataLayer?.push(pageView);
                if (price && product) useTrackItemView(product, price, 1, page);
                pageView = null;
            }
        });
    }
    if (datalayerContainsEvent('cf_after_consent_update')) {
        if (pageView !== null) {
            addUserToDatalayer(userId);
            window?.dataLayer?.push(pageView);
            if (price && product) useTrackItemView(product, price, 1, page);
            pageView = null;
        }
    }
}

function datalayerContainsEvent(event) {
    return window.dataLayer.some(function (item) {
        return item.event === event;
    });
}

function addUserToDatalayer(userId) {
    useTrackUser(userId);
    if (window.location.hash.includes('#state=')) {
        useTrackLogin(userId);
    }
}
